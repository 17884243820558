import React from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import Html from "@components/html"
import PropTypes from "prop-types"
import DashboardExampleCategoryGrid from "@components/dashboard-examples/dashboard-example-category-grid"
import Flex from "@components/elements/flex"
import { ResourceGrid } from "@components/resources/resource-grid"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const KpiExamples = ({ data, pageContext }) => {
  const home = data.home
  const dbCategories = data.dbCategories.edges
  const categories = data.categories.edges
  const parentCategories = data.parentCategories.edges
  const image = home.image
  const imageUrl = image?.cdn

  if (parentCategories.length > 0 && categories.length >= 2) {
    categories.splice(2, 0, parentCategories[0])
  }

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      canonical={pageContext.slug}
      seo={home.seo}
      body={home.body}
      date_updated={home.date_updated}
    >
      <article>
        <ResourceWrapper
          gap="6rem"
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <ResourceHeader
            name={home.title}
            alt={home.title}
            kpi
            subtitle={home.subtitle}
            image={image}
            shadow={false}
            newsletterCta
            links={[
              { text: "Resources", url: "/resources" },
              {
                text: "KPI Examples",
              },
            ]}
          />
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                cta
                inherit
                parseElements
                html={home.body}
                images={home.body_images}
              />
            </Flex>
          </Container>
          {home.show_related && (
            <Container>
              <ResourceGrid
                hideHeader
                title="KPI Examples"
                shadow={false}
                suffix={`Metrics & KPIs`}
                kpi
                examples={categories}
              />
            </Container>
          )}
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                cta
                inherit
                parseElements
                html={home.body2}
                images={home.body2_images}
              />
            </Flex>
          </Container>
          {home.show_dashboards && (
            <Container>
              <DashboardExampleCategoryGrid
                slugPrefix="/resources/dashboard-examples"
                examples={dbCategories}
              />
            </Container>
          )}
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                cta
                inherit
                parseElements
                html={home.body3}
                images={home.body3_images}
              />
            </Flex>
          </Container>
        </ResourceWrapper>
        <KlipsEOPCta />
      </article>
    </Layout>
  )
}

KpiExamples.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default KpiExamples

export const pageQuery = graphql`
  query KpiExamplesPageTemplateQuery($limit: Int!) {
    home: kpiExamplesHome {
      image {
        id
        title
        cdn
        placeholder
      }
      body
      body2
      body3
      body_images {
        cdn
        placeholder
        directus_id
        id
        title
      }
      body2_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      body3_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      title
      subtitle
      date_updated
      seo {
        ...seoData
      }
      show_related
      show_dashboards
    }
    dbCategories: allDashboardExamplesCategories(
      limit: $limit
      sort: { fields: sort, order: ASC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          image {
            id
            title
            cdn
            placeholder
          }
          title
          name
          slug
          summary
        }
      }
    }
    categories: allKpiExamplesCategories(
      limit: $limit
      sort: { fields: sort, order: ASC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          image {
            id
            title
            cdn
            placeholder
          }
          id
          name
          slug
        }
      }
    }
    parentCategories: allKpiExamplesParentCategory(
      limit: $limit
      sort: { fields: date_updated, order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          image {
            id
            title
            cdn
            placeholder
          }
          id
          name
          slug
        }
      }
    }
  }
`
